import type { DragnetBrowser } from '@zg-rentals/dragnet-browser';
import type { LogHttpRequest } from '@zg-rentals/monitor-base';
import type { Logger } from '@zg-rentals/logger-base';
import { DragnetJsExceptionCategory, type JsHttpRequestRecordDto } from '@zg-rentals/ts-dragnet-client';
import { defineReporter, generateDragnetHttpRequest } from '@zg-rentals/monitor-base';

export function getShortUserAgent() {
  if (typeof navigator === 'undefined') return 'server';

  const userAgent = navigator.userAgent;
  let browser, os;

  // Detect browser
  if (userAgent.indexOf('Firefox') > -1) {
    browser = 'Firefox';
  } else if (userAgent.indexOf('SamsungBrowser') > -1) {
    browser = 'Samsung Internet';
  } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
    browser = 'Opera';
  } else if (userAgent.indexOf('Trident') > -1) {
    browser = 'Internet Explorer';
  } else if (userAgent.indexOf('Edge') > -1) {
    browser = 'Edge';
  } else if (userAgent.indexOf('Chrome') > -1) {
    browser = 'Chrome';
  } else if (userAgent.indexOf('Safari') > -1) {
    browser = 'Safari';
  } else {
    browser = 'Unknown Browser';
  }

  // Detect OS
  if (userAgent.indexOf('Win') > -1) {
    os = 'Windows';
  } else if (userAgent.indexOf('Mac') > -1) {
    os = 'MacOS';
  } else if (userAgent.indexOf('X11') > -1) {
    os = 'UNIX';
  } else if (userAgent.indexOf('Linux') > -1) {
    os = 'Linux';
  } else if (userAgent.indexOf('Android') > -1) {
    os = 'Android';
  } else if (userAgent.indexOf('like Mac') > -1) {
    os = 'iOS';
  } else {
    os = 'Unknown OS';
  }

  return `${browser}_on_${os}`.replace(/\s+/g, '_').toLowerCase();
}

function generateHttpRequestFromWindow(context?: Record<string, unknown>): LogHttpRequest {
  const fullContext = context ?? {};
  if (fullContext.userAgent) {
    fullContext.ua = fullContext.userAgent;
    delete fullContext.userAgent;
  }

  return {
    url: window.location.href,
    method: 'GET',
    params: Object.fromEntries(new URLSearchParams(window.location.search)),
    body: JSON.stringify(fullContext),
  };
}

export default (dragnet: DragnetBrowser, logger?: Logger) =>
  defineReporter({
    error: async (err: {
      context?: Record<string, unknown>;
      error: Error;
      httpRequest?: LogHttpRequest;
      isErrorBoundary?: boolean;
    }) => {
      const errHttpRequest = err.httpRequest;

      let httpRequest: JsHttpRequestRecordDto | undefined = undefined;

      try {
        httpRequest = generateDragnetHttpRequest(errHttpRequest ?? generateHttpRequestFromWindow(err.context ?? {}));
      } catch (e) {
        logger?.error('Error while generating dragnet request ' + e.stack);
      }

      await dragnet.reportError({
        httpRequest,
        error: err.error,
        category: err.isErrorBoundary
          ? DragnetJsExceptionCategory.BOUNDARY_BROWSER_ERROR
          : DragnetJsExceptionCategory.BROWSER_ERROR,
      });
    },
  });
