export interface Reporter {
  count?: (name: string, amount: number) => Promise<void>;
  gauges?: (name: string, amount: number) => Promise<void>;
  measurementWithGauges?: (name: string, amount: number) => Promise<void>;
  error?: (args: { error: Error }) => Promise<void>;
}

export function defineReporter(reporter: Reporter) {
  return reporter;
}

export interface Count {
  name: string;
  amount?: number;
}

export interface Measure {
  name: string;
  amount: number;
}

export class Monitor {
  constructor(protected readonly reporters: Array<Reporter> = []) {}

  async count(...counts: Array<Count>) {
    await Promise.all(
      counts.flatMap(({ name, amount = 1 }) => this.reporters.map((reporter) => reporter.count?.(name, amount))),
    ).catch((error) => this.error({ error }));
  }

  async gauges(...measures: Array<Measure>) {
    await Promise.all(
      measures.flatMap(({ name, amount }) => this.reporters.map((reporter) => reporter.gauges?.(name, amount))),
    ).catch((error) => this.error({ error }));
  }

  async measurementWithGauges(...measures: Array<Measure>) {
    await Promise.all(
      measures.flatMap(({ name, amount }) =>
        this.reporters.map((reporter) => reporter.measurementWithGauges?.(name, amount)),
      ),
    ).catch((error) => this.error({ error }));
  }

  async error(arg: { error: Error } & Record<string, unknown>) {
    await Promise.all(this.reporters.map((reporter) => reporter.error?.(arg)));
  }
}
