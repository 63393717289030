import type { Marlin } from './marlin';
import { defineReporter } from '@zg-rentals/monitor-base';

export default (marlin: Marlin, appName: string, serverName: string) =>
  defineReporter({
    count: (name, amount) =>
      marlin.sendCounts({
        serviceName: appName,
        serverName: serverName,
        countStats: {
          [Date.now()]: {
            [name]: { sum: amount },
          },
        },
      }),

    gauges: (name, amount) =>
      marlin.sendGauges({
        serviceName: appName,
        serverName: serverName,
        gauges: {
          [Date.now()]: {
            [name]: {
              count: 1,
              sum: amount,
              min: amount,
              max: amount,
            },
          },
        },
      }),

    measurementWithGauges: async (name, amount) => {
      await marlin.sendMeasurementWithGauges({
        serviceName: appName,
        serverName: serverName,
        measurements: {
          [name]: [{ timeMs: Date.now(), value: amount }],
        },
      });
    },
  });
