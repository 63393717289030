import { definePlugin } from '@zg-rentals/rp-bootstrap-base';
import type { Metric } from 'web-vitals';
import { onCLS, onFCP, onINP, onLCP, onTTFB } from 'web-vitals';

export default definePlugin(({ monitor }) => {
  if (typeof window !== 'undefined') {
    const record = (label: string, good: number, needsImprovement: number) => (metric: Metric) => {
      monitor.measurementWithGauges({
        name: `WebVital ${label}`,
        amount: metric.value,
      });

      if (metric.value <= good) {
        monitor.count({ name: `WebVital ${label} good` });
      } else if (metric.value > good && metric.value <= needsImprovement) {
        monitor.count({ name: `WebVital ${label} needs_improvement` });
      } else {
        monitor.count({ name: `WebVital ${label} poor` });
      }
    };

    onCLS(record('cumulative_layout_shift', 0.1, 0.25));

    onINP(record('interaction_to_next_paint', 200, 500));

    onLCP(record('largest_contentful_paint', 2500, 4000));

    onFCP(record('first_contentful_paint', 1800, 3000));

    onTTFB(record('time_to_first_byte', 800, 1800));
  }
});
