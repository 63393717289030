import datadogRum from './datadogRum';
import getBrowserLogger from '@zg-rentals/logger-browser';
import type { Logger, LoggerOptions } from '@zg-rentals/logger-browser';
import type { Plugin } from '@zg-rentals/rp-bootstrap-base';
import webVitals from './webVitals';
import { Bootstrap, BootstrapBuilder } from '@zg-rentals/rp-bootstrap-base';
import { BrowserTracer } from '@zg-rentals/trace-browser';
import { DragnetBrowser } from '@zg-rentals/dragnet-browser';
import { exceptionReporter, getShortUserAgent } from '@zg-rentals/monitor-browser';
import { MarlinBrowser, marlinReporter } from '@zg-rentals/marlin-browser';
import { Monitor, type Reporter } from '@zg-rentals/monitor-base';
import { getCurrentEnvForClientSide, PRODUCTION } from '@zg-rentals/rental-platform-config';
import { isDev } from '@zg-rentals/environment-utils';
import { logError } from '@zg-rentals/log-error';

export class BootstrapBrowserBuilder extends BootstrapBuilder {
  private loggerOptions: LoggerOptions = {};
  private initializeLogger = (logger: Logger) => logger;

  withLoggerOptions(loggerOptions: LoggerOptions, initializeLogger?: (logger: Logger) => Logger) {
    this.loggerOptions = loggerOptions;
    if (initializeLogger) {
      this.initializeLogger = initializeLogger;
    }
    return this;
  }

  withHostedAppLoggerOptions(hostedApp: string, level = 'warn') {
    return this.withLoggerOptions({ level, logPath: `/${hostedApp}/hostedApp/log?appName=${this.appName}` });
  }

  private readonly plugins: Array<Plugin> = [webVitals];
  withDatadogPlugin(applicationId: string, clientToken: string, service = this.appName) {
    if (typeof window !== 'undefined' && getCurrentEnvForClientSide() === PRODUCTION) {
      this.plugins.push(datadogRum(applicationId, clientToken, service, `${this.buildNumber}`));
    }

    return this;
  }

  build() {
    return new BootstrapBrowser(
      this.appName,
      this.buildNumber,
      this.plugins,
      { ...this.loggerOptions, initializeLogger: this.initializeLogger },
      [
        () => marlinReporter(new MarlinBrowser(this.appName), this.appName, 'browser'),

        (logger) =>
          exceptionReporter(
            new DragnetBrowser({
              appName: this.appName,
              serverName: getShortUserAgent(),
              appVersion: `${this.buildNumber}`,
            }),
            logger.child({ name: 'browser-exception-reporter' }),
          ),
      ],
    );
  }
}

export class BootstrapBrowser extends Bootstrap {
  constructor(
    appName: string,
    buildNumber: number,
    plugins: Array<Plugin>,
    loggerOptions: LoggerOptions & { initializeLogger: (logger: Logger) => Logger },
    reporters: Array<(logger: Logger) => Reporter>,
  ) {
    const logger = loggerOptions.initializeLogger(
      getBrowserLogger({
        name: appName,
        ...loggerOptions,
        logPath: isDev() ? undefined : loggerOptions.logPath,
      }),
    );

    const monitor = new Monitor(reporters.map((reporter) => reporter(logger)));

    const tracer = new BrowserTracer(monitor);

    super(appName, buildNumber, plugins, logger, monitor, tracer);

    this.onRegister(() => {
      monitor.count({ name: 'browser init' });
      monitor.count({ name: `buildNumber browser ${this.buildNumber}` });

      tracer.init();

      window.addEventListener('error', ({ error, filename, lineno, colno }) => {
        if (error != null) {
          logError({
            error,
            context: {
              filename,
              lineNumber: lineno,
              columnNumber: colno,
            },
          });
        }
      });

      window.addEventListener('unhandledrejection', ({ reason }) =>
        logError({ error: new Error(reason), errorType: 'unhandledRejection' }),
      );
    });
  }
}

export { Bootstrap };
