import type { Monitor } from '@zg-rentals/monitor-base';
import { appInfo } from '@zg-rentals/app-info';
import { getClient, setClient } from '@zg-rentals/http-client';
import { setGlobalErrorContext } from '@zg-rentals/log-error';
import { setGlobalMonitor } from '@zg-rentals/monitor-base';
import { setGlobalTracer, type Tracer } from '@zg-rentals/trace-base';
import { type Logger, setGlobalLogger } from '@zg-rentals/logger-base';

export type Plugin = (bootstrap: Bootstrap, appName: string, buildNumber: number) => Promise<unknown> | unknown;

export function definePlugin(plugin: Plugin): Plugin {
  return plugin;
}

export abstract class BootstrapBuilder {
  constructor(
    public readonly appName: string,
    public readonly buildNumber: number,
  ) {}

  abstract build(): Bootstrap;
}

export class Bootstrap {
  private readonly callbacks: Array<() => ReturnType<Plugin>> = [];

  constructor(
    protected readonly appName: string,
    protected readonly buildNumber: number,
    protected readonly plugins: Array<Plugin>,

    public readonly logger: Logger,
    public readonly monitor: Monitor,
    public readonly tracer: Tracer,
    public readonly httpClient = getClient(),
  ) {
    appInfo.set({ name: appName, buildNumber });

    plugins.forEach((plugin) => this.onRegister(() => plugin(this, this.appName, this.buildNumber)));

    setGlobalLogger(logger);
    setGlobalMonitor(monitor);
    setGlobalTracer(tracer);

    setGlobalErrorContext({ appName, buildNumber });

    setClient(httpClient);

    this.onRegister(() => {
      // Note: do not wait for monitor count to flush when registering, i.e. no async/await
      monitor.count({ name: 'bootstrap.register' });
      monitor.count({ name: `buildNumber ${this.buildNumber}` });
    });
  }

  /**
   * Schedule a callback to execute when the bootstrap instance is registered.
   * Useful to recording a startup metric, for example.
   */
  protected onRegister(callback: (typeof this.callbacks)[number]) {
    this.callbacks.push(callback);
  }

  /**
   * Call register _once per runtime_ in your application. For a client-only app
   * this is likey wherever you call ReactDOM.createRoot. For a server app this
   * should be called in your docker-entrypoint. For SSR apps, it should be called
   * in each place.
   */
  public async register() {
    await Promise.all(this.callbacks.slice(0).map((callback) => callback()));
  }
}
