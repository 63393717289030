import { definePlugin } from '@zg-rentals/rp-bootstrap-base';
import { datadogRum } from '@datadog/browser-rum';
import { isbot } from 'isbot';

export default (applicationId: string, clientToken: string, service: string, version: string) =>
  definePlugin(() => {
    if (typeof window !== 'undefined') {
      datadogRum.init({
        applicationId,
        clientToken,
        service,
        version,
        sessionSampleRate: 20,
        sessionReplaySampleRate: 0,
        trackUserInteractions: false,
        allowedTracingUrls: [window.location.origin],
        site: 'datadoghq.com',
        startSessionReplayRecordingManually: true,
        beforeSend: () => !isbot(window.navigator.userAgent),
      });
    }
  });
