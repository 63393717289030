import { makeModuleInstance, warningBanner } from '@zg-rentals/util';
import { Monitor } from './monitor';

export * from './monitor';
export * from './exceptionReporter';

export type LogHttpRequest = {
  method: string;
  url: string;
  headers?: Record<string, string>;
  params?: Record<string, string>;
  body?: string;
  ip?: string;
};

export const { get: getGlobalMonitor, set: setGlobalMonitor } = makeModuleInstance(new Monitor());

export { warningBanner };
